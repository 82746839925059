import _objectSpread from "E:/Project/pigeon/\u79CD\u9E3D\u9972\u517B\u7CFB\u7EDF/admin-web/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import "core-js/modules/web.dom.iterable";

var _this = this;

import router from "./routers";
import store from '@/store';
import Config from '@/settings';
import NProgress from 'nprogress'; // progress bar

import 'nprogress/nprogress.css'; // progress bar style

import { getToken } from '@/utils/auth'; // getToken from cookie

import { checkTemToken } from "@/api/login";
import { encrypt } from '@/utils/rsaEncrypt';
import { buildMenus } from '@/api/system/menu';
import { filterAsyncRouter } from '@/store/modules/permission';
NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

var whiteList = ['/login']; // no redirect whitelist

router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + Config.title;
  }

  NProgress.start();

  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/'
      });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        // 判断当前用户是否已拉取完user_info信息
        store.dispatch('GetInfo').then(function () {
          // 拉取user_info
          // 动态路由，拉取菜单
          loadMenus(next, to);
        }).catch(function () {
          store.dispatch('LogOut').then(function () {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        }); // 登录时未拉取 菜单，在此处拉取
      } else if (store.getters.loadMenus) {
        // 修改成false，防止死循环
        store.dispatch('updateLoadMenus');
        loadMenus(next, to);
      } else {
        next();
      }
    }
  } else {
    /* has no token*/
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      var token = to.query['token'];

      if (token) {
        checkTemToken(token).then(function (res) {
          if (res) {
            //写死大屏访问用户登录
            var user = {
              username: 'datavuser',
              password: encrypt('lZs3UFL2Rued')
            };
            store.dispatch('Login', user).then(function () {
              _this.$router.push({
                path: _this.redirect || '/'
              });
            }).catch(function () {
              next("/login?redirect=".concat(to.fullPath)); // 否则全部重定向到登录页

              NProgress.done();
            }); // next()
          } else {
            next("/login?redirect=".concat(to.fullPath)); // 否则全部重定向到登录页

            NProgress.done();
          }
        });
      } else {
        next("/login?redirect=".concat(to.fullPath)); // 否则全部重定向到登录页

        NProgress.done();
      }
    }
  }
});
export var loadMenus = function loadMenus(next, to) {
  buildMenus().then(function (res) {
    var sdata = JSON.parse(JSON.stringify(res));
    var rdata = JSON.parse(JSON.stringify(res));
    var sidebarRoutes = filterAsyncRouter(sdata);
    var rewriteRoutes = filterAsyncRouter(rdata, true);
    rewriteRoutes.push({
      path: '*',
      redirect: '/404',
      hidden: true
    });
    store.dispatch('GenerateRoutes', rewriteRoutes).then(function () {
      // 存储路由
      // router.addRoutes(rewriteRoutes) // 动态添加可访问路由表
      rewriteRoutes.forEach(function (menu) {
        router.addRoute(menu);
      });
      next(_objectSpread(_objectSpread({}, to), {}, {
        replace: true
      }));
    });
    store.dispatch('SetSidebarRouters', sidebarRoutes);
  });
};
router.afterEach(function () {
  NProgress.done(); // finish progress bar
});
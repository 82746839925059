"use strict";

var _interopRequireDefault = require("E:/Project/pigeon/\u79CD\u9E3D\u9972\u517B\u7CFB\u7EDF/admin-web/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.encrypt = encrypt;
exports.decrypt = decrypt;

var _jsencrypt = _interopRequireDefault(require("jsencrypt/bin/jsencrypt.min"));

// 密钥对生成 http://web.chacuo.net/netrsakeypair
var publicKey = 'MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANL378k3RiZHWx5AfJqdH9xRNBmD9wGD\n' + '2iRe41HdTNF8RUhNnHit5NpMNtGL0NPTSSpPjjI1kJfVorRvaQerUgkCAwEAAQ==';
var privateKey = 'MIIBUwIBADANBgkqhkiG9w0BAQEFAASCAT0wggE5AgEAAkEA0vfvyTdGJkdbHkB8\n' + 'mp0f3FE0GYP3AYPaJF7jUd1M0XxFSE2ceK3k2kw20YvQ09NJKk+OMjWQl9WitG9p\n' + 'B6tSCQIDAQABAkA2SimBrWC2/wvauBuYqjCFwLvYiRYqZKThUS3MZlebXJiLB+Ue\n' + '/gUifAAKIg1avttUZsHBHrop4qfJCwAI0+YRAiEA+W3NK/RaXtnRqmoUUkb59zsZ\n' + 'UBLpvZgQPfj1MhyHDz0CIQDYhsAhPJ3mgS64NbUZmGWuuNKp5coY2GIj/zYDMJp6\n' + 'vQIgUueLFXv/eZ1ekgz2Oi67MNCk5jeTF2BurZqNLR3MSmUCIFT3Q6uHMtsB9Eha\n' + '4u7hS31tj1UWE+D+ADzp59MGnoftAiBeHT7gDMuqeJHPL4b+kC+gzV4FGTfhR9q3\n' + 'tTbklZkD2A=='; // 加密

function encrypt(txt) {
  var encryptor = new _jsencrypt.default();
  encryptor.setPublicKey(publicKey); // 设置公钥

  return encryptor.encrypt(txt); // 对需要加密的数据进行加密
} // 解密


function decrypt(txt) {
  var encryptor = new _jsencrypt.default();
  encryptor.setPrivateKey(privateKey);
  return encryptor.decrypt(txt);
}